<template>
  <div class="lms-course-data">
    <lms-card v-if="loaded">
      <lms-card-title>
        <template #icon>
          <div class="course-progress">
            <v-progress-circular
              :rotate="270"
              :size="96"
              :width="6"
              :value="progress"
              color="primary"
            >
              {{ progress }}%
            </v-progress-circular>
          </div>
        </template>
        <span @click="go">
          {{ course.title[courseCurrentLanguage] }}
        </span>
      </lms-card-title>
    </lms-card>
  </div>
</template>

<script>
import { isLinear } from '../../../../services/courses';
import { getCourseProgress } from '../../../../services/progress';
import lessonTypes from '../../../../data/lessonTypes';
export default {
  data: () => ({
    progress: 0,
  }),
  computed: {
    loaded() {
      return typeof this.course._id != 'undefined';
    },
    course() {
      return this.$store.getters['course/current'];
    },
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
  },
  created() {
    const structure = isLinear() ? 'linear' : 'modular';
    this.$store.subscribeAction((action, state) => {
      if (action.type === 'progress/update') {
        setTimeout(() => {
          this.progress = getCourseProgress(this.course, structure);
        }, 1000);
      }
    });
    setTimeout(() => {
      this.progress = getCourseProgress(this.course, structure);
    }, 4000);
  },
  methods: {
    go() {
      this.$router.push({
        name: 'course-view',
        params: {
          id: this.$route.params.courseid,
        },
      });
    },
  },
  mounted() {
    let lesson = this.$store.getters['lesson/current'];

    this.$gtm.trackEvent({
      category: 'course-internal',
      event: 'click',
      action: 'course-internal-click',
      label: `course-internal-click-${lessonTypes[lesson.category].title}`,
    });
  },
};
</script>

<style lang="scss">
.lms-course-data {
  .v-progress-circular {
    margin-left: 12px;
  }
  .v-progress-circular__info {
    font-size: 16px;
  }
  .course-progress {
    padding: 32px 0 32px 26px;
  }
  .v-progress-circular__overlay {
    stroke: #fab330;
  }
}
</style>
