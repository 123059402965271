<template>
  <div>
    <TitleExam />
    <div class="es-info-time-lock screens-modules">
      <div>
        {{ $t('labels.requireCompletedModulesBeforeExam') }}
      </div>
    </div>
  </div>
</template>

<script>
import TitleExam from './Title.vue';

export default {
  components: {
    TitleExam,
  },
};
</script>
