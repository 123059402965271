<template>
  <div>
    <button @click="toggle" class="es-answers-btn" v-allowed="[userTypes.ADMIN]">
      {{ showingAnswers ? 'hide' : 'show' }} answers
    </button>
    <TitleExam />
    <QuestionCards />
  </div>
</template>

<script>
import QuestionCards from './QuestionCards.vue';
import TitleExam from './Title.vue';

export default {
  components: {
    QuestionCards,
    TitleExam,
  },
  computed: {
    showingAnswers() {
      return this.$store.state.exam.examQuestionsData.showingAnswers;
    },
  },
  methods: {
    toggle() {
      this.$store.state.exam.examQuestionsData.showingAnswers = !this.$store
        .state.exam.examQuestionsData.showingAnswers;
    },
  },
};
</script>

<style lang="scss">
.es-answers-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #0c2033;
  padding: 5px 24px;
}
</style>
