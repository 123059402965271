<template>
  <div class="viewer-test--title-exam">
    {{ lesson.title[courseCurrentLanguage] }}
  </div>
</template>

<script>
export default {
  computed: {
    lesson() {
      return this.$store.getters['lesson/current'];
    },
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
  },
};
</script>

<style lang="scss">
.viewer-test--title-exam {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
  padding: 0 2px;
  text-align: left;
}
</style>
