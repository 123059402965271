<template>
  <!-- mobile table of content -->
  <div>
    <div id="mtoc">
      <div class="mtoc--handler" :class="{ desktop: isDesktop }">
        <div v-if="isDesktop">
          <div v-if="mTOCCtl.snapedOn == POSITION.BOTTOM">
            <v-icon>mdi-arrow-up-thick</v-icon>
          </div>
          <div v-else><v-icon>mdi-arrow-down-thick</v-icon></div>
        </div>
      </div>
      <div class="mtoc--content">
        <!-- <div class="mtoc--content--actions" v-if="isDesktop">
          <div v-if="mTOCCtl.snapedOn == POSITION.BOTTOM">
            <v-icon>mdi-arrow-up-thick</v-icon>
          </div>
          <div v-else><v-icon>mdi-arrow-down-thick</v-icon></div>
        </div> -->
      </div>
    </div>
    <div id="mtoc-content-holder"></div>
  </div>
</template>

<script>
import Bowser from 'bowser';

const POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
};
export default {
  data: () => ({
    POSITION,
    divs: {
      // mobile table of content element
      mTOC: null,
      mTOCContentHolder: null,
      mTOCContent: null,
      lessonContentDiv: null,
    },
    // pre-defined position of the handler
    positions: {
      top: 0,
      bottom: 0,
    },
    mTOCCtl: {
      currentPxPosition: 0,
      snapedOn: POSITION.BOTTOM,
    },
    hasInited: false,
    wasTouchedTimeout: null,
    isDesktop: true,
    checkInterval: null,
  }),
  computed: {
    screenSize() {
      return this.$store.state.system.screenSizeCtl.size.current;
    },
    gap() {
      return this.$store.state.system.screenSizeCtl.gap;
    },
  },
  watch: {
    'mTOCCtl.currentPxPosition': function() {
      this.moveContent();
    },
  },
  created() {
    this.$store.subscribeAction(action => {
      if (action.type === 'lesson/finishedLoad') {
        this.$nextTick(() => {
          this.init();
        });
      } else if (action.type === 'lesson/select') {
        this.mTOCCtl.snapedOn = POSITION.BOTTOM;
        this.moveHandler(this.positions.bottom);
      }
    });
  },
  mounted() {
    this.checkInterval = setInterval(() => {
      const contentDiv = document.querySelector('.mvw--content-view');
      if (contentDiv) {
        const lastBottomPosition = this.positions.bottom;
        this.setBottomPosition();
        if (
          lastBottomPosition != this.positions.bottom &&
          this.mTOCCtl.snapedOn == POSITION.BOTTOM
        ) {
          this.moveHandler(this.positions.bottom);
        }
      }
    }, 500);
  },
  methods: {
    async init() {
      const bowser = Bowser.getParser(window.navigator.userAgent);
      this.isDesktop = bowser.getPlatformType(true) == 'desktop';
      this.setDivs();
      this.setPositions();

      if (!this.hasInited) {
        this.setListeners();
      }

      if (!this.hasInited) {
        // extra
        this.divs.mTOC.onclick = e => {
          e.preventDefault();
        };
      }

      this.hasInited = true;
    },
    setDivs() {
      this.divs.mTOC = document.getElementById('mtoc');
      this.divs.mTOCContentHolder = document.getElementById(
        'mtoc-content-holder'
      );
      this.divs.mTOCContent = document.querySelector('.mvw--topic-selector');
      this.divs.lessonContentDiv = document.querySelector('.mvw--content-view');
    },
    setPositions() {
      this.setBottomPosition();
      this.positions.top = 65;
    },
    touchEventHandler(e) {
      e.preventDefault();

      const et = e.targetTouches[0];
      this.moveHandler(et.clientY);
    },
    touchendEventHandler(_) {
      if (this.mTOCCtl.currentPxPosition > this.positions.bottom) {
        this.moveHandler(this.positions.bottom);
        this.mTOCCtl.snapedOn = POSITION.BOTTOM;
      } else {
        const closeToTop = Math.abs(
          this.mTOCCtl.currentPxPosition - this.positions.top
        );
        const closeToBottom = Math.abs(
          this.mTOCCtl.currentPxPosition - this.positions.bottom
        );

        if (closeToTop <= closeToBottom) {
          this.moveHandler(this.positions.top);
          this.mTOCCtl.snapedOn = POSITION.TOP;
        } else {
          this.moveHandler(this.positions.bottom);
          this.mTOCCtl.snapedOn = POSITION.BOTTOM;
        }
      }
    },
    clickEventHandler(e) {
      if (this.mTOCCtl.snapedOn == POSITION.BOTTOM) {
        this.moveHandler(this.positions.top);
        this.mTOCCtl.snapedOn = POSITION.TOP;
      } else {
        this.moveHandler(this.positions.bottom);
        this.mTOCCtl.snapedOn = POSITION.BOTTOM;
      }
    },
    setListeners() {
      // mobiles
      this.divs.mTOC.addEventListener('touchmove', this.touchEventHandler);
      this.divs.mTOC.addEventListener('touchend', this.touchendEventHandler);

      this.divs.mTOC.addEventListener('click', this.clickEventHandler);
    },
    moveHandler(position) {
      this.mTOCCtl.currentPxPosition = position;

      this.divs.mTOC.style.bottom = `calc(100vh - ${position + 37}px)`;
    },
    moveContent() {
      this.divs.mTOCContentHolder.style.height = `calc(100vh - ${this.mTOCCtl
        .currentPxPosition + 37}px)`;
    },
    setBottomPosition() {
      let bottom =
        this.divs.lessonContentDiv.offsetTop +
        this.divs.lessonContentDiv.clientHeight +
        50;
      const max = this.screenSize - 45;
      if (bottom > max) {
        bottom = max;
      }
      if (this.gap) {
        bottom += this.gap;
      }
      this.positions.bottom = bottom;
    },
  },
  beforeDestroy() {
    clearInterval(this.checkInterval);
  },
};
</script>
