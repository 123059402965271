<template>
  <div
    class="es-alert-last-attempt"
    v-if="showScreen != ''"
    :class="{ 'es-orange': showScreen == availableScreens.LAST_ATTEMPT }"
  >
    <div v-if="showScreen == availableScreens.LAST_ATTEMPT_AVAILABLE">
      <v-icon class="es-white">mdi-alert-circle</v-icon> Última tentativa
      disponível
    </div>
    <div
      v-if="showScreen == availableScreens.LAST_ATTEMPT"
      class="es-alert-last-attempt--last-attempt"
    >
      <v-icon>mdi-alert-circle</v-icon>
      {{ $t('labels.noAttempt') }}
    </div>
  </div>
</template>

<script>
const availableScreens = {
  LAST_ATTEMPT: 'lastAttempt',
  LAST_ATTEMPT_AVAILABLE: 'lastAttemptAvailable',
};
export default {
  data: () => ({
    availableScreens,
  }),
  computed: {
    showScreen() {
      let screen = '';
      const currentRunIsFinished = this.$store.state.exam.currentRunIsFinished;
      const attemptsCountInfo = this.$store.getters['exam/attemptsCountInfo'];

      if (
        currentRunIsFinished &&
        attemptsCountInfo.current >= attemptsCountInfo.max
      ) {
        screen = availableScreens.LAST_ATTEMPT;
      } else if (
        !currentRunIsFinished &&
        attemptsCountInfo.current + 1 == attemptsCountInfo.max
      ) {
        screen = availableScreens.LAST_ATTEMPT_AVAILABLE;
      }

      return screen;
    },
  },
};
</script>

<style lang="scss">
.es-alert-last-attempt {
  background-color: #0c2033;
  margin-bottom: 2px;
  padding: 8px 12px;

  &.es-orange {
    background-color: #ab6a3a !important;
  }

  &--last-attempt {
    color: #4f301a;
  }

  i.es-white {
    color: #fff;
  }

  i {
    margin-right: 10px;
  }
}
</style>
