<template>
  <div :key="refreshSidebar">
    <view-module-expandable-card
      v-for="(mdl, index) in course.modules"
      :key="mdl._id"
      :title="mdl.title[courseCurrentLanguage]"
      :index="index"
    >
      <div
        v-for="lesson in mdl.lessons"
        :key="lesson._id"
        class="modular_lesson_row"
        :class="{
          active: $route.params.lessonid == lesson._id,
          exam: lesson.category == '2',
        }"
        @click="view(mdl._id, lesson._id)"
      >
        <div class="mlr--icon">
          <v-icon
            color="primary"
            v-if="
              isLessonDone(
                {
                  courseID: $route.params.courseid,
                  moduleID: mdl._id,
                  lessonID: lesson._id,
                },
                'modular'
              )
            "
            :class="{
              notApproved: lesson.category == 2 && !examResult(lesson).approved,
            }"
          >
            <!-- mdi-checkbox-marked-circle -->
            {{
              lesson.category == 2 && !examResult(lesson).approved
                ? 'mdi-alert-circle'
                : 'mdi-checkbox-marked-circle'
            }}
          </v-icon>
          <v-icon color="white" v-else>
            mdi-checkbox-blank-circle-outline
          </v-icon>
        </div>
        <div class="lms-title">
          <div>
            {{ getTitle(lesson) }}
          </div>
          <div v-if="lesson.category == 2" class="exam">Exam</div>
        </div>
      </div>
    </view-module-expandable-card>
  </div>
</template>

<script>
import ViewModuleExpandableCard from '../../module/ViewModuleExpandableCard.vue';
import { isLessonDone } from '../../../../services/progress';

export default {
  components: {
    ViewModuleExpandableCard,
  },
  data: () => ({
    refreshSidebar: 0,
  }),
  computed: {
    course() {
      return this.$store.getters['course/current'];
    },
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
  },
  created() {
    this.$store.subscribeAction(action => {
      if (
        action.type === 'progress/update' ||
        action.type === 'exam/reloadSidebar'
      ) {
        setTimeout(() => {
          this.refreshSidebar++;
        }, 500);
      }
    });
  },
  methods: {
    isLessonDone,
    examResult(item) {
      const user = this.$store.getters['auth/user'];
      try {
        const courseDataAttempts = user.examAttempts.find(
          attempt => attempt.courseID == this.$route.params.courseid
        );
        const userModuleScore = courseDataAttempts.modules
          .find(mdl => mdl.moduleID == item._id)
          .list.slice(-1)
          .pop().score;
        return {
          max: item.data.questions.length,
          min: item.data.min_questions,
          result: userModuleScore,
          perc: ((userModuleScore * 100) / item.data.questions.length).toFixed(
            1
          ),
          approved: userModuleScore >= item.data.min_questions,
        };
      } catch (e) {
        return {
          approved: false,
        };
      }
    },
    getTitle(lesson) {
      try {
        return lesson.title[this.courseCurrentLanguage];
      } catch (e) {
        return '*corrupted data lesson';
      }
    },
    async view(moduleID, lessonID) {
      await this.$store.dispatch('module/select', moduleID);
      await this.$store.dispatch('lesson/select', lessonID);
      this.$router.push({
        name: 'course-modular-lesson-view',
        params: {
          courseid: this.$route.params.courseid,
          moduleid: moduleID,
          lessonid: lessonID,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../../../assets/scss/base/variables';
.modular_lesson_row {
  display: flex;
  /* border-bottom: 1px solid $global-bg; */
  border-top: 1px solid $cs-blue--global-bg;
  padding: 22px 32px;

  &:hover {
    background-color: $box-hover;
  }

  &.active {
    background-color: $box-selected;
  }

  &.exam {
    border-right: 4px solid $box-hover;
  }

  .lms-title {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .exam {
      background-color: #0058b3;
      color: #fff;
      font-size: 0.9rem;
      line-height: 25px;
      padding: 0 20px;
      border-radius: 100px;
      height: 25px;
    }
  }
  .notApproved {
    color: #ab6a3a !important;
  }

  .v-icon {
    margin-right: 22px;
  }
}

.mlv--lesson {
  font-weight: 400;
  font-size: 14px;
  /* border-top: 1px solid $cs-blue--global-bg; */
  padding: 10px 10px;

  &:hover {
    background-color: $box-hover;
  }
}
</style>
