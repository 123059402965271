const moduleUtils = {
  getMaxScore(attempts) {
    const sortedScores = attempts.map((item) => +item.score)
      .sort((a, b) => a > b ? -1 : (a < b ? 1 : 0))
    const highScore = sortedScores[0]
    return highScore
  },
  hasPassed(attempts, module) {
    const minQuestoins = +module.min_questions
    const highScore = this.getMaxScore(attempts)
    return highScore >= minQuestoins
  },
  getExamPercentage(attempts, mdl) {
    const questions = mdl.data.questions.length
    const maxScore = moduleUtils.getMaxScore(attempts)
    if (maxScore > 0) {
      const value = +((maxScore * 100) / questions).toFixed(1)
      return value
    }
    return 0
  },
  supportMaterialFilename: (name, file) => {
    const extension = file.split('.').pop()
    return `${name}.${extension}`
  },
}

export default moduleUtils
