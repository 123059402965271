<template>
  <div class="es-lastresult screens-modules" v-if="scoreInfo.has">
    <div class="screens-modules--title">
      Resultado anterior:
    </div>
    <span class="es-lastresult--current">{{ scoreInfo.lastScore }}</span
    >/{{ scoreInfo.max }}
    <span class="es-lastresult--status">
      ( {{ scoreInfo.approved ? $t('labels.approved') : $t('labels.insufficientScore') }} )
    </span>
  </div>
</template>

<script>
export default {
  computed: {
    scoreInfo() {
      return this.$store.getters['exam/lastScoreInfo'];
    },
  },
};
</script>

<style lang="scss">
.es-lastresult {
  &--current {
    font-weight: 900;
  }
  &--status {
    color: #495766;
  }
}
</style>
