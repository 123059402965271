<template>
  <div :class="classes" @click.prevent="onClick">
    <div class="support-material-item__wrapper">
      <v-btn icon :disabled="loading">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      downloaded: false,
    };
  },
  methods: {
    onClick() {
      if (!this.loading) {
        this.downloaded = true;
        this.$emit('click');
      }
    },
  },
  computed: {
    classes() {
      return {
        'support-material-item': true,
        'support-material-item--loading': this.loading,
        'support-material-item--downloaded': this.downloaded,
      };
    },
    icon() {
      if (this.loading) {
        return 'mdi-loading mdi-spin';
      }
      return 'mdi-download';
    },
  },
};
</script>

<style lang="scss">
.support-material-item {
  cursor: pointer;

  &__wrapper {
    padding: 20px;
    background: #0c2033;
    /* border: 1px solid #fff; */

    &:hover {
      background: #012344;
    }
  }
  &--downloaded {
    .support-material-item__wrapper {
      background: #2a3c4d;
    }
  }
}
</style>
