<template>
  <div class="lesson-header">
    <div class="lesson-header--title">
      {{ lesson.title[courseCurrentLanguage] }}
    </div>

    <div class="lesson-header--viewed-rating">
      <div class="lesson-header--viewed-rating--viewed">
        <mark-as-read-module />
      </div>
      <div class="lesson-header--viewed-rating--rating">
        <v-rating v-model="rating" hover half-increments />
      </div>
    </div>
  </div>
</template>

<script lang="js">
import MarkAsReadModule from '../../../shared/MarkAsReadModule.vue';

export default {
	components: {
		MarkAsReadModule,
	},
	data: () => ({
		rating: 0
	}),
	computed: {
		lesson() {
			return this.$store.getters['lesson/current'];
		},
		courseCurrentLanguage() {
			return this.$store.getters['course/courseCurrentLanguage'];
		},
	},
  watch: {
    rating(value) {
      this.$store.dispatch('rating/setRating', {
        rating: value,
        parentId: this.$route.params.lessonid,
      });
    }
  },
	created(){
		this.getData()
	},
	methods: {
		async getData() {
			this.rating = await this.$store.dispatch('lesson/getLessonRating');
		},
	}
}
</script>

<style lang="scss">
.lesson-header {
  &--title {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 12px;
    padding: 0 2px;
    text-align: left;
  }

  &--viewed-rating {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &--viewed {
      color: blueviolet;
    }

    &--rating {
      color: coral;
    }
  }
}
</style>
