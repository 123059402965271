<template>
  <div class="es-attempts-control screens-modules">
    <div class="screens-modules--title">
      Tentativas:
    </div>
    <span class="es-attempts-control--current">{{
      attemptsCountInfo.current
    }}</span
    >/{{ attemptsCountInfo.max }}
    <span class="es-attempts-control--status">
      ( {{ attemptsCountInfo.max - attemptsCountInfo.current + ' ' + $t('labels.remainig')}} )
    </span>
  </div>
</template>

<script>
export default {
  computed: {
    attemptsCountInfo() {
      return this.$store.getters['exam/attemptsCountInfo'];
    },
  },
};
</script>

<style lang="scss">
.es-attempts-control {
  &--current {
    font-weight: 900;
  }
  &--status {
    color: #495766;
  }
}
</style>
