<template>
  <div>
    <TitleExam />
    <InfoTimeLock />
    <LastResult />
    <AttemptsControl />
  </div>
</template>

<script>
import AttemptsControl from './AttemptsControl.vue';
import InfoTimeLock from './InfoTimeLock.vue';
import LastResult from './LastResult.vue';
import TitleExam from './Title.vue';

export default {
  components: {
    AttemptsControl,
    InfoTimeLock,
    LastResult,
    TitleExam,
  },
};
</script>
