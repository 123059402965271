<template>
  <div class="video-viewer">
    <lesson-header />

    <div class="mod-player" ref="modPlayerRef">
      <iframe
        id="vimeo_player"
        ref="vimeo_player"
        :src="
          'https://player.vimeo.com/video/' +
            lesson.data.video[courseCurrentLanguage]
        "
        width="100%"
        :height="videoHeight"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      />
    </div>

    <br />
    <!-- DESCRIPTION -->
    <div class="video-viewer--description" v-if="hasDescription">
      {{ lesson.description[courseCurrentLanguage] }}
    </div>

    <!-- SUPORT MATERIALS -->

    <!-- <div class="mod-title">
      <f-mark-as-read-module :is-done="isDone" @read="onMarkAsRead" />
      <div>
        <span>
          {{ view.title[courseLanguage] }}
        </span> -->
    <!-- <f-rating-system
          v-bind:rating="{
            size: 28,
            parentId: this.$route.params.moduleid,
            value: rating,
          }"
        />
        <v-progress-circular
          :rotate="-90"
          :size="30"
          :width="15"
          :value="current"
          color="primary"
        /> -->
    <!-- </div>
    </div> -->
    <!-- <div class="mod-description">
      <span>{{ view.description[courseLanguage] }}</span>
    </div> -->
    <!-- <f-support-material-list
      :value="value"
      :language="language"
      :courseId="courseId"
      :view="view"
    /> -->
  </div>
</template>

<script>
import Player from '@vimeo/player';
import { isLinear } from '../../services/courses';
import SupportMaterialList from '../Courses/SupportMaterialList.vue';
import LessonHeader from './lesson/view/Header.vue';

export default {
  components: {
    'f-support-material-list': SupportMaterialList,
    LessonHeader,
  },
  data: _ => ({
    courseID: '',
    moduleID: '',
    player: null,
    duration: null,
    seeker: null,
    modPlayerWidth: 0,
    modPlayerHeight: 0,
    videoInfo: null,
  }),
  computed: {
    lesson() {
      return this.$store.getters['lesson/current'];
    },
    hasDescription() {
      return typeof this.lesson.description != 'undefined';
    },
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
    current() {
      return this.$store.state.progress.selected.progress;
    },
    videoHeight() {
      if (!this.videoInfo || !this.videoInfo.height) {
        return '100%';
      }
      const boxWidth = this.modPlayerWidth;
      const boxHeight = this.modPlayerHeight;
      const videoWidth = this.videoInfo.width;
      const videoHeight = this.videoInfo.height;
      let height = Math.floor((videoHeight * boxWidth) / videoWidth);
      if (height > boxHeight) {
        height = boxHeight;
      }
      return height;
    },
  },
  created() {
    this.courseID = this.$route.params.courseid;
    this.moduleID = this.$route.params.moduleid;
    this.$store.commit('system/pageLoaded');
  },
  async mounted() {
    var playerElem = this.$refs.vimeo_player;

    this.player = new Player(playerElem);

    this.player.on(
      'play',
      function() {
        this.setDuration();
        this.startSeeker();

        this.$gtm.trackEvent({
          category: 'course-internal',
          event: 'click',
          action: 'course-internal-click',
          label: `course-internal-play-video`,
        });
        console.log('play');
      }.bind(this)
    );

    this.player.on(
      'pause',
      function() {
        this.stopSeeker();
        this.registerProgress();

        this.$gtm.trackEvent({
          category: 'course-internal',
          event: 'click',
          action: 'course-internal-click',
          label: `course-internal-pause-video`,
        });
      }.bind(this)
    );

    this.player.on(
      'ended',
      function() {
        this.stopSeeker();
        this.registerProgress(true);

        this.$gtm.trackEvent({
          category: 'course-internal',
          event: 'click',
          action: 'course-internal-click',
          label: `course-internal-end-video`,
        });

        this.$store.dispatch('lesson/showNextLessonScreen');
      }.bind(this)
    );

    this.player.setCurrentTime(this.$store.state.progress.selected.current);

    window.addEventListener('resize', this.onResize);

    Promise.all([
      this.player.getVideoWidth(),
      this.player.getVideoHeight(),
    ]).then(dimensions => {
      const width = dimensions[0];
      const height = dimensions[1];
      this.videoInfo = { width, height };
      this.onResize();
    });

    this.$store.commit('system/loading', false, { root: true });
  },
  methods: {
    async setDuration() {
      if (!this.duration) {
        this.duration = await this.player.getDuration();
      }
    },
    startSeeker() {
      if (this.seeker == null) {
        this.seeker = setInterval(
          function() {
            this.registerProgress();
          }.bind(this),
          3000
        );
      }
    },
    stopSeeker() {
      clearInterval(this.seeker);
      this.seeker = null;
    },
    async registerProgress(end = false) {
      var current, progress;
      if (!end) {
        current = await this.player.getCurrentTime();
        current = Math.floor(current);
        progress = Math.ceil((100 * current) / this.duration);
      } else {
        current = this.duration;
        progress = 100;
      }
      var payload;
      if (isLinear()) {
        payload = {
          course: this.$route.params.courseid,
          lesson: this.$route.params.lessonid,
        };
      } else {
        payload = {
          course: this.$route.params.courseid,
          module: this.$route.params.moduleid,
          lesson: this.$route.params.lessonid,
        };
      }
      await this.$store.dispatch('progress/update', {
        current,
        progress,
        ids: payload,
      });
      // await this.$store.dispatch('auth/getData', ['progress']);
      // this.$emit('afterProgress');
    },
    onResize() {
      this.modPlayerWidth = +this.$refs.modPlayerRef.offsetWidth;
      this.modPlayerHeight = +window.innerHeight - 145;
    },
  },
  destroyed() {
    this.stopSeeker();
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.video-viewer {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding-top: 10px;

  .mod-controls {
    color: #fff;
    height: 50px;
    background-color: #000;
    text-align: center;
    line-height: 50px;
  }
  .mod-title {
    text-align: right;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      padding-right: 20px;
      display: inline-block;
    }
  }
  .mod-player {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .mod-description {
    color: #fff;
    width: 70%;
    margin: auto;
  }
}
</style>
