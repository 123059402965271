<template>
  <div>
    <lms-card :key="cardRefresher">
      <div v-for="item in course.modules" :key="item._id">
        <div
          class="linear_lesson_row"
          :class="{
            active: $route.params.lessonid == item._id,
            exam: item.category == '2',
          }"
          @click="goToLesson(item._id)"
        >
          <div
          class="llr--icon"
          >
            <v-icon
              color="primary"
              v-if="isLessonDone(
                {
                  courseID: $route.params.courseid,
                  lessonID: item._id,
                }
              )"
              :class="{
                notApproved: item.category == 2 && !examResult(item).approved,
              }"
            >
              {{
                item.category == 2 && !examResult(item).approved
                  ? 'mdi-alert-circle'
                  : 'mdi-checkbox-marked-circle'
              }}
            </v-icon>
            <v-icon v-else>
              mdi-checkbox-blank-circle-outline
            </v-icon>
          </div>
          <div class="lms-title">
            <div>
              {{ item.title[courseCurrentLanguage] }}
            </div>
            <div v-if="item.category == 2" class="exam">Exam</div>
          </div>
        </div>
      </div>
    </lms-card>
  </div>
</template>

<script>
import { isLessonDone } from '../../../../services/progress';

export default {
  data: () => ({
    cardRefresher: 0,
  }),
  computed: {
    course() {
      return this.$store.getters['course/current'];
    },
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
  },
  created() {
    console.log('ok')
    this.$store.subscribeAction((action, state) => {
      if (
        action.type === 'progress/update' ||
        action.type === 'exam/reloadSidebar'
      ) {
        setTimeout(() => {
          this.cardRefresher++;
        }, 500);
      }
    });
  },
  methods: {
    isLessonDone,
    examResult(item) {
      const user = this.$store.getters['auth/user'];
      try {
        if (
          !this.isLessonDone({
            courseID: this.$route.params.courseid,
            lessonID: item._id,
          })
        ) {
          console.log('ok')
          throw new Error('sem tentativas');
        }

        const courseDataAttempts = user.examAttempts.find(
          attempt => attempt.courseID == this.$route.params.courseid
        );

        const userModuleScore = courseDataAttempts.modules
          .find(mdl => mdl.moduleID == item._id.toString())
          .list.slice(-1)
          .pop().score;

        return {
          max: item.data.questions.length,
          min: item.data.min_questions,
          result: userModuleScore,
          perc: ((userModuleScore * 100) / item.data.questions.length).toFixed(
            1
          ),
          approved: userModuleScore >= item.data.min_questions,
        };
      } catch (e) {
        return false;
      }
    },
    goToLesson(lessonid) {
      this.$store.dispatch('lesson/select', lessonid);
      this.$router.push({
        name: 'course-linear-lesson-view',
        params: {
          courseid: this.$route.params.courseid,
          lessonid,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../../../assets/scss/base/variables';

.linear_lesson_row {
  border-bottom: 1px solid $global-bg;
  padding: 22px 32px;
  display: flex;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: $box-hover;
  }

  &.active {
    background-color: $box-selected;
  }

  &.exam {
    border-right: 4px solid $box-hover;
  }

  .v-icon {
    margin-right: 22px;
  }

  .lms-title {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .exam {
      background-color: #0058b3;
      color: #fff;
      font-size: 0.9rem;
      line-height: 25px;
      padding: 0 20px;
      border-radius: 100px;
      height: 25px;
    }
  }

  .notApproved {
    color: #ab6a3a !important;
  }
}
.cs-blue {
  .linear_lesson_row {
    border-bottom-color: $cs-blue--global-bg;

    &:hover {
      background-color: $box-hover;
    }

    &.active {
      background-color: $box-selected;
    }

    &.exam {
      border-right: 4px solid $box-hover;
    }
  }
}
</style>
