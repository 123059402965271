<template>
  <div>
    <!-- SIDEBAR - BS -->
    <f-linear v-if="isLinear" />
    <f-modular v-else />
  </div>
</template>

<script lang="js">
import FLinear from './SidebarLinear.vue';
import FModular from './SidebarModular.vue';

export default {
	components: {
		FLinear,
		FModular
	},
	computed: {
		isLinear(){
			return this.$store.getters['course/isLinear'];
		},
		course() {
			return this.$store.getters['course/current'];
		}
	}
}
</script>
