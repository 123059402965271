<template>
  <div class="vmec--wrapper">
    <div class="vmec--wrapper--header" @click="toggleModule">
      <div class="vmec--title-wrapper">
        <div class="vmec--module-counter">Módulo {{ +index + 1 }}</div>
        <div class="vmec--module-title">
          {{ title }}
        </div>
      </div>
      <div class="vmec--header-icon" :class="{ active: isOpen }">
        <v-icon>mdi-chevron-left</v-icon>
      </div>
    </div>
    <div class="vmec--wrapper--content">
      <ExpandableDiv :expanded="isOpen">
        <slot />
      </ExpandableDiv>
    </div>
  </div>
</template>

<script>
import ExpandableDiv from '../../shared/ExpandableDiv.vue';

export default {
  props: ['index', 'title'],
  components: {
    ExpandableDiv,
  },
  data: () => ({
    isOpen: true,
  }),
  methods: {
    toggleModule() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss">
.vmec--wrapper {
  background-color: #0c2033;
  margin-bottom: 16px;
  &--header {
    display: flex;
    justify-content: space-between;

    .vmec--title-wrapper {
      margin: 12px 0 12px 24px;
      .vmec--module-counter {
        font-size: 12px;
        color: #495766 !important;
      }
      .vmec--module-title {
        font-weight: 900;
        width: calc(100% - 40px);
      }
    }
    .vmec--header-icon i {
      color: #fab330;
    }
    .vmec--header-icon {
      transform-origin: center center;
      transition: all 0.5s;
    }
    .vmec--header-icon.active {
      transform: rotate(-90deg);
    }
    color: peru;
  }
  &--content {
    color: aqua !important;
  }
}
</style>
