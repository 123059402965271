<template>
  <div class="es-scoreboard screens-modules">
    <div class="es-scoreboard--title screens-modules--title">
      Pontuação:
    </div>
    <div class="es-scoreboard--score">
      <div class="es-scoreboard--score--holder">
        <div class="es-scoreboard--score--current">{{ currentScore }}</div>
        <div class="es-scoreboard--score--max">/{{ scoreInfo.max }}</div>
      </div>
      <div class="es-scoreboard--score--perc">{{ perc }}%</div>
    </div>
    <div class="es-scoreboard--status">
      {{ approved ? $t('labels.approved') : $t('labels.insufficientScore') }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentScore() {
      let score;
      const isFinished = this.$store.state.exam.currentRunIsFinished;
      if (isFinished) {
        score = this.$store.state.exam.attempts.currentScore;
      } else {
        score = this.scoreInfo.lastScore;
      }
      return score;
    },
    scoreInfo() {
      return this.$store.getters['exam/lastScoreInfo'];
    },
    approved() {
      return this.currentScore >= this.scoreInfo.min;
    },
    perc() {
      let perc = (this.currentScore * 100) / this.scoreInfo.max;
      return perc.toFixed()
    },
  },
};
</script>

<style lang="scss">
.es-scoreboard {
  &--title {
    font-size: 12px;
    color: #495766 !important;
  }
  &--score {
    &--holder {
      display: flex;
      justify-content: center;
    }

    &--current {
      font-weight: 900;
      font-size: 60px;
      color: #fff;
    }
    &--max {
      font-size: 30px;
      margin-top: 16px;
    }

    &--perc {
      margin-top: -20px;
      text-align: center;
    }
  }
  &--status {
    padding: 24px 0 36px 0;
    text-align: center;
  }
}
</style>
