<template>
  <div class="viewer-test-component">
    <component :is="currentScreen" />
  </div>
</template>

<script>
import ApprovedLock from './lesson/view/ViewerTest/ScreenApprovedLock.vue';
import AttemptsLock from './lesson/view/ViewerTest/ScreenAttemptLock.vue';
import Exam from './lesson/view/ViewerTest/ScreenExam.vue';
import Loading from './lesson/view/ViewerTest/ScreenLoading.vue';
import RequirementLock from './lesson/view/ViewerTest/ScreenRequirementLock.vue';
import Result from './lesson/view/ViewerTest/ScreenResult.vue';
import Start from './lesson/view/ViewerTest/ScreenStart.vue';
import TimeLock from './lesson/view/ViewerTest/ScreenTimeLock.vue';

import { screenNames } from '../../services/exam';

export default {
  components: {
    [screenNames.ApprovedLock]: ApprovedLock,
    [screenNames.AttemptsLock]: AttemptsLock,
    [screenNames.Exam]: Exam,
    [screenNames.Loading]: Loading,
    [screenNames.RequirementLock]: RequirementLock,
    [screenNames.Result]: Result,
    [screenNames.Start]: Start,
    [screenNames.TimeLock]: TimeLock,
  },
  computed: {
    currentScreen() {
      return this.$store.getters['exam/currentScreen'];
    },
  },
  created() {
    this.$store.dispatch('exam/loadInfoData');
  },
  destroyed() {
    this.$store.dispatch('exam/clearData');
  },
};
</script>

<style lang="scss">
.screens-modules {
  background-color: #0c2033;
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 2px;

  &--title {
    font-size: 12px;
    color: #495766 !important;
    text-align: left;
  }
}
</style>
