<template>
  <div class="support-material-viewer__wrapper">
    <div class="support-material-viewer__list">
      <f-support-material-item
        v-for="(item, index) of list"
        :key="index"
        :item="item"
        :loading="filesDownloadInProgress.has(item.file)"
        @click="() => download(item)"
      />
    </div>
  </div>
</template>

<script>
import SupportMaterialItem from './SupportMaterialItem';
import fileUtils from '../../helpers/file_utils';
import moduleUtils from '../../helpers/module_utils';
import { isLinear } from '../../services/courses';

export default {
  props: ['value', 'courseId', 'view'],
  components: {
    'f-support-material-item': SupportMaterialItem,
  },
  data() {
    return {
      filesDownloadInProgress: new Set(),
      language: '',
      _value: {},
    };
  },
  computed: {
    list() {
      const { _value, language } = this;
      return _value.materials
        .filter(item => item[language] && item[language].file)
        .map(item => item[language])
        .map(item => ({
          name: item.name,
          file: item.file,
        }));
    },
  },
  created() {
    this.language = this.$store.getters['course/courseCurrentLanguage'];
    this._value = this.$store.getters['lesson/current'].data;
  },
  methods: {
    async download(item) {
      this.filesDownloadInProgress = new Set(
        this.filesDownloadInProgress.add(item.file)
      );

      let payload;
      if (isLinear()) {
        payload = {
          courseID: this.$route.params.courseid,
          moduleID: this.$route.params.lessonid,
          file: item.file,
        };
      } else {
        payload = {
          courseID: this.$route.params.courseid,
          moduleID: this.$route.params.moduleid,
          lessonID: this.$route.params.lessonid,
          file: item.file,
        };
      }
      const result = await this.$store.dispatch(
        'lesson/module_download_support_material',
        payload
      );

      if (+result.status === 1) {
        const extension = item.file.split('.').pop();
        const name = moduleUtils.supportMaterialFilename(item.name, item.file);
        const type = fileUtils.getMimeTypeByExtension(extension);

        fileUtils.download({
          name,
          type,
          data: result.body.body,
          isBase64: true,
        });
      } else {
        this.$store.commit('snackbar/show', {
          content: this.$t('alerts.general.errorToDownload'),
          type: 'error',
        });
      }

      this.filesDownloadInProgress.delete(item.file);
      this.filesDownloadInProgress = new Set(this.filesDownloadInProgress);
    },
  },
};
</script>
