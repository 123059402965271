<template>
  <div class="post-viewer">
    <lesson-header />

    <div class="mod-post" ref="modPost">
      <iframe :src="clearContentTarget" id="content-target" scrolling="no">
      </iframe>
    </div>
  </div>
</template>

<script>
import { isLinear } from '../../services/courses';
import LessonHeader from './lesson/view/Header.vue';

const resizeIframe = () => {
  var div = document.getElementById('content-target');
  div.style.height = div.contentWindow.document.body.scrollHeight + 'px';
};

export default {
  components: {
    LessonHeader,
  },
  data: _ => ({
    scrolledEnd: false,
    clearContentTarget: '',
  }),
  computed: {
    lesson() {
      return this.$store.getters['lesson/current'];
    },
    content() {
      return this.lesson.data.post[this.courseCurrentLanguage];
    },
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
  },
  mounted() {
    this.setScroll();
    this.get();

    window.resizeIframe = resizeIframe;
  },
  methods: {
    async get() {
      this.rating = await this.$store.dispatch('lesson/getLessonRating');

      const { courseid, moduleid, lessonid } = this.$route.params;
      this.clearContentTarget = `/clear-content/course/${courseid}/module/${moduleid}/lesson/view/${lessonid}`;
    },
    async setScroll() {
      window.addEventListener('scroll', this.onScroll);
      this.onScroll();
    },
    removeScroll() {
      window.removeEventListener('scroll', this.onScroll);
    },
    onScroll() {
      if (!this.scrolledEnd) {
        const isTheEnd =
          window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 200;
        if (isTheEnd) {
          this.scrolledEnd = true;
          this.registerProgress();
        }
      }
    },
    async registerProgress() {
      let payload;
      if (isLinear()) {
        payload = {
          course: this.$route.params.courseid,
          lesson: this.$route.params.lessonid,
        };
      } else {
        payload = {
          course: this.$route.params.courseid,
          module: this.$route.params.moduleid,
          lesson: this.$route.params.lessonid,
        };
      }
      await this.$store.dispatch('progress/update', {
        current: 1,
        progress: 100,
        ids: payload,
      });
      this.$emit('afterProgress');
    },
  },
  destroyed() {
    this.removeScroll();
  },
};
</script>

<style lang="scss">
#content-target {
  height: 0px;
  width: 100%;
  border: none;
}
.post-viewer {
  .mod-title {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 36px;
  }
  /* .mod-post {
    max-width: 800px;
    margin: 0 auto;
    padding: 0; */

  /* all: initial;

    & * {
      all: unset;
    } */
  /* } */
  /* .mod-post img,
  .mod-post iframe,
  .mod-post video {
    max-width: 100%;
    display: block;
  } */

  .mod-end {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
  }

  .mod-rating {
    margin-right: -5px;
  }
}
</style>
