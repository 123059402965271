<template>
  <div class="es-info-time-lock screens-modules">
    <div>
      {{ waitLabel }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    waitLabel() {
      const {
        hours,
        minutes,
        seconds,
      } = this.$store.state.exam.attempts.timeControl.waitTimers;
      let count, label;
      if (seconds) {
        count = seconds;
        label = 'second(s)';
      } else if (minutes) {
        count = minutes;
        label = 'minute(s)';
      } else {
        count = hours;
        label = 'hour(s)';
      }
      return this.$i18n.t('alerts.courses.youNeedWait', {
        count,
        label,
      });
    },
  },
};
</script>

<style lang="scss">
.es-info-time-lock {
  & > div {
    text-align: center;
    padding: 36px 0;
    font-weight: 700;
  }
}
</style>
