<template>
  <div>
    <!-- content show -->
    <!-- <f-module-control /> -->

    <f-video-viewer v-if="lesson.category == 0" />
    <f-pdf-viewer v-if="lesson.category == 1" />
    <f-test-viewer v-if="lesson.category == 2" />
    <!-- <f-exam-answers v-if="lesson.category == 2" /> -->
    <f-material-viewer v-if="lesson.category == 3" />
    <f-post-viewer v-if="lesson.category == 4" />

    <support-material />
  </div>
</template>

<script>
import FVideoViewer from '../../../Courses/ViewerVideo.vue';
import FTestViewer from '../../../Courses/ViewerTest.vue';
import FPdfViewer from '../../../Courses/ViewerPDF.vue';
import FMaterialViewer from '../../../Courses/ViewerSupportMaterial.vue';
import FPostViewer from '../../ViewerPost.vue';
import SupportMaterial from './SupportMaterial.vue';

export default {
  components: {
    FVideoViewer,
    FTestViewer,
    FPdfViewer,
    FMaterialViewer,
    FPostViewer,
    SupportMaterial,
  },
  computed: {
    lesson() {
      return this.$store.getters['lesson/current'];
    },
  },
};
</script>
