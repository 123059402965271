<template>
  <div>
    <TitleExam />
    <ScoreBoard />
    <!-- <LastResult /> -->
    <AttemptsControl />
    <AlertLastAttempt />
    <StartButton />
    <!-- <NextLesson /> -->
  </div>
</template>

<script>
import AlertLastAttempt from './AlertLastAttempt.vue';
import AttemptsControl from './AttemptsControl.vue';
import LastResult from './LastResult.vue';
import NextLesson from './NextLesson.vue';
import ScoreBoard from './Scoreboard.vue';
import StartButton from './StartButton.vue';
import TitleExam from './Title.vue';

export default {
  components: {
    AlertLastAttempt,
    AttemptsControl,
    LastResult,
    NextLesson,
    ScoreBoard,
    StartButton,
    TitleExam,
  },
  destroyed() {
    this.$store.state.exam.currentRunIsFinished = false;
  },
};
</script>
