import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.refreshSidebar},_vm._l((_vm.course.modules),function(mdl,index){return _c('view-module-expandable-card',{key:mdl._id,attrs:{"title":mdl.title[_vm.courseCurrentLanguage],"index":index}},_vm._l((mdl.lessons),function(lesson){return _c('div',{key:lesson._id,staticClass:"modular_lesson_row",class:{
        active: _vm.$route.params.lessonid == lesson._id,
        exam: lesson.category == '2',
      },on:{"click":function($event){return _vm.view(mdl._id, lesson._id)}}},[_c('div',{staticClass:"mlr--icon"},[(
            _vm.isLessonDone(
              {
                courseID: _vm.$route.params.courseid,
                moduleID: mdl._id,
                lessonID: lesson._id,
              },
              'modular'
            )
          )?_c(VIcon,{class:{
            notApproved: lesson.category == 2 && !_vm.examResult(lesson).approved,
          },attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(lesson.category == 2 && !_vm.examResult(lesson).approved ? 'mdi-alert-circle' : 'mdi-checkbox-marked-circle')+" ")]):_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" mdi-checkbox-blank-circle-outline ")])],1),_c('div',{staticClass:"lms-title"},[_c('div',[_vm._v(" "+_vm._s(_vm.getTitle(lesson))+" ")]),(lesson.category == 2)?_c('div',{staticClass:"exam"},[_vm._v("Exam")]):_vm._e()])])}),0)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }