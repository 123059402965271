<template>
  <div class="support-materials" v-if="show">
    <br />
    <div>
      <div
        v-for="(material, idex) in materials"
        :key="index"
        class="item-support"
        @click="downloadURL(material)"
      >
        {{ material[courseCurrentLanguage].name }}

        <div class="is-infos">
          <div class="extension">
            <v-icon>mdi-file</v-icon>
            {{ getExtension(material) }}
          </div>

          <div class="icon">
            <v-icon>mdi-download</v-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { downloadCourseMaterial } from '../../../../services/file';

export default {
	computed: {
		lesson() {
			return this.$store.getters['lesson/current'];
		},
		courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
		materials() {
			return this.lesson.data.materials;
		},
    show() {
      let show = false;
      if(
        typeof this.materials != 'undefined' &&
        this.materials.length &&
        (this.lesson.category == 0 || this.lesson.category == 4)
      ){
        show = true;
      }
      return show;
    }
	},
	methods: {
		getExtension(material){
			const itemFilenameArr = material[this.courseCurrentLanguage].file.split('.')
			return itemFilenameArr[itemFilenameArr.length-1].toUpperCase();
		},
    downloadURL(material){
      downloadCourseMaterial(material[this.courseCurrentLanguage].file,material[this.courseCurrentLanguage].name)
    }
	}

}
</script>

<style lang="scss" scoped>
.support-materials {
}
.item-support {
  background-color: #0c2033;
  display: inline-block;
  padding: 14px;
  border-radius: 2px;
  width: 200px;
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    background-color: #012344;
  }

  .is-infos {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    font-size: 12px;
    i {
      font-size: 18px;
    }
    .extension {
      i {
        margin-right: 8px;
      }
    }
  }
}
</style>
