<template>
  <div class="pdf-viewer">
    <lesson-header />

    <!-- PDF -->
    <div :class="wrapperClasses" v-dragscroll="zoom > 5" ref="wrapper">
      <div class="pdf-loading" v-if="loading">
        <v-progress-circular indeterminate color="primary" />
      </div>
      <pdf
        ref="viewer"
        class="viewer"
        :src="url"
        :page="page"
        :style="pdfStyles"
        @loaded="loadingDone"
      />
    </div>

    <!-- VIEW CONTROL -->

    <div :class="controlsClasses">
      <div class="controls__item controls__item--left">
        <div class="zoom">
          <div :class="zoomInClasses" @click.prevent="zoomIn">
            <v-icon>mdi-plus</v-icon>
          </div>
          <div :class="zoomOutClasses" @click.prevent="zoomOut">
            <v-icon>mdi-minus</v-icon>
          </div>
          <div class="zoom__info">
            {{ zoomPercentage }}
          </div>
        </div>
      </div>
      <div class="controls__item">
        <div class="pagination">
          <div class="controls__button" @click="previous">
            <v-icon>mdi-arrow-left</v-icon>
          </div>
          <div class="pages">
            {{ page + '/' + numPages }}
          </div>
          <div class="controls__button" @click="next">
            <v-icon>mdi-arrow-right</v-icon>
          </div>
        </div>
      </div>
      <div class="controls__item controls__item--right"></div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
import LessonHeader from './lesson/view/Header.vue';
import { isLinear } from '../../services/courses';

export default {
  props: ['value', 'title', 'current-progress', 'is-done'],
  components: {
    pdf,
    LessonHeader,
  },
  data: () => ({
    page: 1,
    url: '',
    numPages: 0,
    zoom: 5,
    loading: true,
    language: '',
    // rating: 5,
  }),
  computed: {
    current() {
      return this.$store.state.progress.selected.progress;
    },
    pdfStyles() {
      const width = (this.zoom * 100) / 5;
      return { width: `${width}%` };
    },
    wrapperClasses() {
      return {
        'pdf-viewer__wrapper': true,
        'pdf-viewer__wrapper--dragcursor': this.zoom > 5,
        'pdf-viewer__wrapper--loading': this.loading,
      };
    },
    controlsClasses() {
      return {
        controls: true,
        'controls--loading': this.loading,
      };
    },
    zoomInClasses() {
      return {
        controls__button: true,
        'zoom__control zoom__control--in': true,
        'zoom__control zoom__control--disabled': this.zoom === 10,
      };
    },
    zoomOutClasses() {
      return {
        controls__button: true,
        'zoom__control zoom__control--out': true,
        'zoom__control zoom__control--disabled': this.zoom === 1,
      };
    },
    zoomPercentage() {
      const value = (this.zoom * 100) / 5;
      return `${value}%`;
    },
  },
  created() {
    this.language = this.$store.getters['course/courseCurrentLanguage'];
  },
  mounted() {
    const token = this.$store.getters['auth/userToken'];
    let _value = this.$store.getters['lesson/current'].data;
    this.url = `${this.$API_URL}file/${_value[this.language]}?token=${token}`;
    pdf.createLoadingTask(this.url).promise.then(pdf => {
      this.numPages = pdf.numPages;
      this.page = this.$store.state.progress.selected.current;
      if (this.page === 0) {
        this.page = 1;
      }
      this.adjustHeight();
    });
    // document.querySelector('html').classList.add('no-scroll');
    // document.querySelector('pdf-viewr').classList.add('no-scroll');
  },
  destroyed() {
    // document.querySelector('html').classList.remove('no-scroll');
    // document.querySelector('html').classList.remove('no-scroll');
  },
  methods: {
    adjustHeight() {
      const wrapper = document.querySelector('.pdf-viewer__wrapper');
      const title = document.querySelector('.lesson-header');
      setTimeout(() => {
        wrapper.style.height = `calc(100vh - ${title.offsetHeight +
          20 +
          95}px)`;
      }, 500);
    },
    // async get() {
    //   // this.rating = await this.$store.dispatch('lesson/getLessonRating');
    // },
    next() {
      if (this.page == this.numPages) {
        this.$store.dispatch('lesson/showNextLessonScreen');
      }
      if (this.page < this.numPages) {
        this.page++;
      }
      this.progress();
      this.scrollTop();
    },
    previous() {
      if (this.page > 1) {
        this.page--;
      }
      this.progress();
      this.scrollTop();
    },
    async progress() {
      var current = this.page,
        progress = Math.floor((100 * this.page) / this.numPages);
      let payload
      if (isLinear()) {
        payload = {
          course: this.$route.params.courseid,
          lesson: this.$route.params.lessonid,
        };
      } else {
        payload = {
          course: this.$route.params.courseid,
          module: this.$route.params.moduleid,
          lesson: this.$route.params.lessonid,
        };
      }
      await this.$store.dispatch('progress/update', {
        current,
        progress,
        ids: payload,
      });
      this.$emit('afterProgress');
    },
    zoomIn() {
      if (this.zoom < 10) {
        this.zoom += 1;
      }
    },
    zoomOut() {
      if (this.zoom > 1) {
        this.zoom -= 1;
      }
    },
    scrollTop() {
      this.$refs.wrapper.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    loadingDone() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.no-scroll {
  overflow: hidden;
}
.pdf-viewer {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-align: center;

  &__wrapper {
    overflow: auto;
    width: 100%;
    transition: height 0.5s;
    height: calc(100vh - 205px);
    &--dragcursor {
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
    &--loading {
      overflow: hidden;
      .viewer {
        opacity: 0;
      }
    }
  }
  .mod-title,
  .mod-controls {
    color: #fff;
    height: 104px;
    background-color: #000;
    text-align: center;
    line-height: 50px;
  }
  .mod-title {
    text-align: right;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      padding-right: 20px;
      display: inline-block;
    }
  }
  .mod-rating {
    margin-right: -30px;
  }
  .viewer {
    display: inline-block !important;
    padding: 10px 10px 0 10px;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    height: 30px;
    position: absolute;
    left: 0;
    right: 0;
    /* left: 50%;
    transform: translateX(-50%); */
    bottom: 0px;
    width: 410px;
    margin: 0 auto;
    &--loading {
      opacity: 0;
    }
    &__item {
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      justify-content: center;
      &--left {
        justify-content: flex-start;
      }
      &--right {
        justify-content: flex-end;
      }
    }
    &__button {
      width: 50px;
      background-color: #ffc107;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .pagination {
      display: flex;
      justify-content: center;
    }
    .pages {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      background-color: #000000;
      color: #ffffff;
    }
    .zoom {
      display: flex;
      justify-content: center;
      &__control {
        &--disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
      &__info {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 900;
        padding: 0 10px;
      }
    }
  }

  .pdf-loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.viewed-rating {
  display: flex;
  justify-content: space-between;
  height: 45px;
  margin-bottom: 30px;
}
</style>
