import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"es-question-cards"},[_vm._l((_vm.questions),function(item,index){return _c(VCard,{key:index,staticClass:"question",attrs:{"elevation":"0"}},[_c(VCardTitle,[_vm._v(" "+_vm._s(item.title[_vm.language])+" ")]),_c(VCardText,[(_vm.hasImage(item))?_c('div',[_c('img',{staticClass:"question-image",attrs:{"src":_vm.getImage(item),"alt":item.title[_vm.language]},on:{"click":() => _vm.openImage(item)}})]):_vm._e(),(item.type === '0')?_c(VRadioGroup,{on:{"change":_vm.checkValidForm},model:{value:(_vm.userAnswers[item.id]),callback:function ($$v) {_vm.$set(_vm.userAnswers, item.id, $$v)},expression:"userAnswers[item.id]"}},[_vm._l((item.answers),function(elem,idx){return _c(VRadio,{key:'ar_' + idx,attrs:{"label":elem.title[_vm.language],"value":elem.id}})}),(_vm.showingAnswers)?_c('div',_vm._l((item.answers),function(elem,idx){return _c('div',{key:'ara_' + idx},[(elem.id == item.result)?_c('div',{staticClass:"item_answer_show"},[_vm._v(" "+_vm._s(elem.title[_vm.language])+" ")]):_vm._e()])}),0):_vm._e()],2):_c('div',{staticClass:"check_responses"},[_vm._l((item.answers),function(elem,idx){return _c(VCheckbox,{key:'ac_' + idx,staticClass:"check_responses--item",attrs:{"label":elem.title[_vm.language],"hide-details":"","name":'check_' + item.id,"value":elem.id},on:{"change":function($event){return _vm.setChecker(item.id)}}})}),(_vm.showingAnswers)?_c('div',_vm._l((item.answers),function(elem,idx){return _c('div',{key:'aca_' + idx},[(elem.check)?_c('div',{staticClass:"item_answer_show"},[_vm._v(" "+_vm._s(elem.title[_vm.language])+" ")]):_vm._e()])}),0):_vm._e()],2)],1)],1)}),_c(VBtn,{attrs:{"color":"primary","elevation":"0","large":"","disabled":!_vm.validForm},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$i18n.t('labels.save')))])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }