<template>
  <div v-if="show">
    <button @click="startExam" class="es-start-button">
      {{
        currentRunIsFinished ? $t('labels.restartExam') : $t('labels.startExam')
      }}
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    currentRunIsFinished() {
      return this.$store.state.exam.currentRunIsFinished;
    },
    show() {
      let showButton = true;

      // const currentRunIsFinished = this.$store.state.exam.currentRunIsFinished;
      const attemptsCountInfo = this.$store.getters['exam/attemptsCountInfo'];
      const timeBetweenAttempts = +this.$store.state.exam.systemSettings
        .timeBetweenAttempts;

      if (
        this.currentRunIsFinished &&
        (attemptsCountInfo.current >= attemptsCountInfo.max ||
          timeBetweenAttempts)
      ) {
        showButton = false;
      }

      return showButton;
    },
  },
  methods: {
    startExam() {
      this.$store.dispatch('exam/openExam');
      this.$store.dispatch('lesson/expandTest');

      let gtmLabel = 'course-internal-start-exam';
      if (this.$store.state.exam.currentRunIsFinished)
        gtmLabel = 'course-internal-retry-exam';

      this.$gtm.trackEvent({
        category: 'course-internal',
        event: 'click',
        action: 'course-internal-click',
        label: `${gtmLabel}`,
      });
    },
  },
};
</script>

<style lang="scss">
.es-start-button {
  background-color: #fab330;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  padding: 12px 24px;
  text-align: center;
  transition: all 0.1s;

  &:hover {
    font-weight: 900;
    padding: 14px 24px;
  }
}
</style>
