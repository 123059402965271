<template>
  <div>
    <TitleExam />
    <InfoLastAttempt />
    <LastResult />
    <div class="es-info">
      * {{ $t('alerts.courses.manyTries') }}
    </div>
  </div>
</template>

<script>
import InfoLastAttempt from './InfoLastAttempt.vue';
import LastResult from './LastResult.vue';
import TitleExam from './Title.vue';

export default {
  components: {
    InfoLastAttempt,
    LastResult,
    TitleExam,
  },
};
</script>

<style lang="scss">
.es-info {
  padding: 8px 12px;
  color: #3e5060;
}
</style>
