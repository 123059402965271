import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('lms-card',{key:_vm.cardRefresher},_vm._l((_vm.course.modules),function(item){return _c('div',{key:item._id},[_c('div',{staticClass:"linear_lesson_row",class:{
          active: _vm.$route.params.lessonid == item._id,
          exam: item.category == '2',
        },on:{"click":function($event){return _vm.goToLesson(item._id)}}},[_c('div',{staticClass:"llr--icon"},[(_vm.isLessonDone(
              {
                courseID: _vm.$route.params.courseid,
                lessonID: item._id,
              }
            ))?_c(VIcon,{class:{
              notApproved: item.category == 2 && !_vm.examResult(item).approved,
            },attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(item.category == 2 && !_vm.examResult(item).approved ? 'mdi-alert-circle' : 'mdi-checkbox-marked-circle')+" ")]):_c(VIcon,[_vm._v(" mdi-checkbox-blank-circle-outline ")])],1),_c('div',{staticClass:"lms-title"},[_c('div',[_vm._v(" "+_vm._s(item.title[_vm.courseCurrentLanguage])+" ")]),(item.category == 2)?_c('div',{staticClass:"exam"},[_vm._v("Exam")]):_vm._e()])])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }