<template>
  <div class="module-view-wrapper">
    <lms-default-loader v-if="isLoading" />
    <f-container topBottom v-else>
      <Teleport :disabled="!useMTOC" to="#mtoc-content-holder">
        <div class="mvw--topic-selector">
          <f-course-data />
          <certificate-alert />
          <f-sidebar />
        </div>
      </Teleport>

      <div class="mvw--content-view">
        <next-screen />
        <f-content-show :key="contentKey" />
      </div>
    </f-container>
    <TOCMobile v-if="useMTOC" />
  </div>
</template>

<script>
import FCourseData from '../../../components/Courses/module/view/CourseData.vue';
import FContentShow from '../../../components/Courses/lesson/view/ContentShow.vue';
import FSidebar from '../../../components/Courses/lesson/view/Sidebar.vue';
import NextModuleScreenOnEnd from '../../../components/Courses/NextModuleScreenOnEnd.vue';
import CertificateAlert from '../../../components/Courses/lesson/view/CertificateAlert.vue';
import TOCMobile from '../../../components/Courses/lesson/view/TOCMobile.vue';
import Teleport from 'vue2-teleport';
import Bowser from 'bowser';

export default {
  components: {
    FCourseData,
    FContentShow,
    FSidebar,
    NextScreen: NextModuleScreenOnEnd,
    CertificateAlert,
    TOCMobile,
    Teleport,
  },
  data: () => ({
    refresher: 0,
    useMTOC: false,
    refreshControl: null,
  }),
  computed: {
    course() {
      return this.$store.getters['course/current'];
    },
    contentKey() {
      return JSON.stringify({
        name: this.$route.name,
        params: this.$route.params,
      });
    },
  },
  watch: {
    contentKey: function() {
      this.$store.state.lesson.nextScreen = false;
      this.get();
    },
  },
  created() {
    this.get();
  },
  mounted() {
    this.useMTOC = window.innerWidth <= 1100;

    // @TODO: remove after tests
    const bowser = Bowser.getParser(window.navigator.userAgent);
    if (bowser.getPlatformType(true) == 'desktop') {
      window.onresize = () => {
        clearTimeout(this.refreshControl);
        this.refreshControl = setTimeout(() => {
          window.location.reload();
        }, 3000);
      };
    }
  },
  methods: {
    async get() {
      // get user progress
      await this.$store.dispatch('auth/getData', [
        'progress',
        'examAttempts',
        'certificates',
      ]);

      await this.$store.dispatch('course/get', this.$route.params.courseid);
      if (this.$route.name.includes('modular')) {
        this.$store.dispatch('module/select', this.$route.params.moduleid);
      }
      await this.$store.dispatch('lesson/select', this.$route.params.lessonid);

      this.$store.commit('system/pageLoaded');
      await this.$store.dispatch('lesson/finishedLoad');
    },
  },
};
</script>
