<template>
  <div>
    <TitleExam />

    <div class="screens-modules">
      <v-progress-circular indeterminate color="primary" :size="100" /><br />
      <br />
      {{ $t('labels.loading') }}
    </div>
  </div>
</template>

<script>
import TitleExam from './Title.vue';

export default {
  components: {
    TitleExam,
  },
};
</script>
