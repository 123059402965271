<template>
  <div class="support-material-viewer">
    <lesson-header />

    <!-- CONTENT -->
    <f-support-material-list
      :value="value"
      :language="language"
      :courseId="courseId"
      :view="view"
    />
  </div>
</template>

<script>
import SupportMaterialList from './SupportMaterialList.vue';
import LessonHeader from './lesson/view/Header.vue';
import { isLinear } from '../../services/courses';

export default {
  props: [
    'value',
    'courseId',
    'view',
    'title',
    'language',
    'is-done',
    'rating',
  ],
  components: {
    'f-support-material-list': SupportMaterialList,
    LessonHeader,
  },
  computed: {
    lesson() {
      return this.$store.getters['lesson/current'];
    },
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
  },
  methods: {
    async setProgress() {
      let payload;
      if (isLinear()) {
        payload = {
          course: this.$route.params.courseid,
          lesson: this.$route.params.lessonid,
        };
      } else {
        payload = {
          course: this.$route.params.courseid,
          module: this.$route.params.moduleid,
          lesson: this.$route.params.lessonid,
        };
      }
      await this.$store.dispatch('progress/update', {
        current: 1,
        progress: 100,
        ids: payload,
      });
      this.$emit('afterProgress');
    },
  },
  mounted() {
    this.setProgress();
  },
};
</script>

<style lang="scss">
.support-material-viewer {
  &__wrapper {
    padding: 0;
  }

  &__list {
    display: grid;
    grid-gap: 1px;
    margin: 0 auto;
  }

  .mod-title {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 36px;
  }

  .mod-end {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
  }

  .mod-rating {
    margin-right: -5px;
  }
}
</style>
