<template>
  <div class="certificate-alert">
    <ExpandableDiv :expanded="hasCertificate">
      <div class="certificate-alert--btn" @click="viewCertificates">
        Você tem certificado para esse curso.
        <div class="certificate-alert--small">
          Clique aqui para ver seus certificados
        </div>
      </div>
    </ExpandableDiv>
  </div>
</template>

<script>
import ExpandableDiv from '../../../shared/ExpandableDiv.vue';
import { getCourseProgress } from '../../../../services/progress';

export default {
  components: {
    ExpandableDiv,
  },
  data: () => ({
    hasCertificate: false,
  }),
  created() {
    this.$store.subscribeAction(action => {
      if (action.type === 'exam/reloadSidebar') {
        setTimeout(() => {
          this.checkIfCertificate();
        }, 500);
      }
    });
    this.checkIfCertificate();
  },
  methods: {
    /**
     * Check if user has a certificate for the current course
     */
    async checkIfCertificate() {
      const course = this.$store.getters['course/current'];
      const user = this.$store.getters['auth/user'];
      const structure = course.structure || 'modular';
      const progress = getCourseProgress(course, structure);
      let hasCertificate = false;

      if (progress == 100 && course.generateCertificate) {
        try {
          const cert = user.certificates.find(
            cert => cert.courseID == this.$route.params.courseid
          );
          if (cert) {
            hasCertificate = true;
          } else {
            const payload = {
              score: progress,
              course: course,
            };
            let response = await this.$store.dispatch(
              'user/updateCourseProgress',
              payload
            );
            response;
            hasCertificate = true;
          }
        } catch (e) {
          //
        }
      }

      this.hasCertificate = hasCertificate;
    },
    viewCertificates() {
      this.$router.push({
        name: 'course-list',
        query: {
          tab: 'certificates',
        },
      });

      this.$gtm.trackEvent({
        category: 'course-internal',
        event: 'click',
        action: 'course-internal-click',
        label: `course-internal-download-certificate`,
      });
    },
  },
};
</script>

<style lang="scss">
.certificate-alert {
  &--btn {
    background-color: #fab330;
    color: #000;
    padding: 8px 24px;
    cursor: pointer;
  }
  &--small {
    color: #000;
    font-size: 12px;
  }
}
</style>
