<template>
  <div class="mark-as-read-module" @click="onClick">
    <v-icon color="primary" v-if="isDone">mdi-checkbox-marked-circle</v-icon>
    <v-icon color="white" v-else>mdi-checkbox-blank-circle-outline</v-icon>
    <span class="mark-as-read-module--text">
      {{ label }}
    </span>
  </div>
</template>

<script>
import course from '../../router/course';
import { isLessonDone } from '../../services/progress';

export default {
  data: () => ({
    isDone: false,
  }),
  computed: {
    label() {
      if (this.isDone) {
        return this.$i18n.t('labels.moduleWasRead');
      }
      return this.$i18n.t('labels.moduleMarkAsRead');
    },
    course() {
      return this.$store.getters['course/current'];
    },
  },
  created() {
    this.$store.subscribeAction((action, state) => {
      if (action.type === 'progress/update') {
        setTimeout(() => {
          this.getData();
        }, 1000);
      }
    });
    this.getData();
  },
  methods: {
    async getData() {
      if (this.course.structure === 'linear') {
        this.isDone = isLessonDone({
          courseID: this.$route.params.courseid,
          lessonID: this.$route.params.lessonid,
        });
      } else {
        this.isDone = isLessonDone(
          {
            courseID: this.$route.params.courseid,
            moduleID: this.$route.params.moduleid,
            lessonID: this.$route.params.lessonid,
          },
          'modular'
        );
      }
    },
    async onClick() {
      if (!this.isDone) {
        this.setLessonDone();
        this.isDone = true;
      }
    },
    async setLessonDone() {
      let data = {
        current: 0,
        progress: 100,
        ids: {
          course: this.$route.params.courseid,
          lesson: this.$route.params.lessonid,
        },
      };
      if (course.structure === 'linear') {
        await this.$store.dispatch('progress/update', data);
      } else {
        data.ids.module = this.$route.params.moduleid;
        await this.$store.dispatch('progress/update', data);
      }

      this.$gtm.trackEvent({
        category: 'course-internal',
        event: 'click',
        action: 'course-internal-click',
        label: 'course-internal-mark-as-read',
      });

      return;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base/variables';

.mark-as-read-module {
  padding: 8px 26px;
  border: 1px solid $box-selected;
  border-radius: 8px;
  display: flex;
  font-weight: 800;
  cursor: pointer;

  &--text {
    display: block;
    margin-left: 10px;
  }
}
</style>
