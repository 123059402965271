<template>
  <div>
    <TitleExam />
    <LastResult />
    <AttemptsControl />
    <AlertLastAttempt />
    <StartButton />
  </div>
</template>

<script>
import AlertLastAttempt from './AlertLastAttempt.vue';
import AttemptsControl from './AttemptsControl.vue';
import LastResult from './LastResult.vue';
import StartButton from './StartButton.vue';
import TitleExam from './Title.vue';

export default {
  components: {
    AlertLastAttempt,
    AttemptsControl,
    LastResult,
    StartButton,
    TitleExam,
  },
};
</script>
