<template>
  <div class="end_div" v-if="endDivShow">
    <div class="close">
      <div @click="closeEndDiv">
        <v-icon>mdi-close</v-icon>
      </div>
    </div>
    <!-- <div v-if="lastModule">
      <div @click="end" class="end-pointer">
        <div class="icon-border">
          <v-icon>mdi-check-bold</v-icon>
        </div>
        <br />
        {{ $tt.capitalize($t('labels.endCourse')) }}
      </div>
    </div>
    <div v-else>-->
    <div>
      <div @click="next" class="end-pointer">
        <div class="icon-border">
          <v-icon>mdi-skip-next</v-icon>
        </div>
        <div class="module-title">
          {{ nextLessonData.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {} from '../../services/courses';

export default {
  data: () => ({
    nextLessonData: {
      courseID: null,
      ID: null,
      moduleID: null,
      title: '',
      loaded: false,
    },
  }),
  computed: {
    course() {
      return this.$store.getters['course/current'];
    },
    endDivShow: {
      get() {
        return this.$store.state.lesson.nextScreen;
      },
      set(value) {
        this.$store.state.lesson.nextScreen = value;
      },
    },
    contentKey() {
      return JSON.stringify({
        name: this.$route.name,
        params: this.$route.params,
      });
    },
  },
  watch: {
    contentKey: function() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.$store.getters['course/isLinear']) {
        const { courseid: courseID, lessonid: lessonID } = this.$route.params;
        const nextIndex =
          this.course.modules.findIndex(el => el._id == lessonID) + 1;
        const nextID = this.course.modules[nextIndex]._id;
        this.nextLessonData.title = this.course.modules[nextIndex].title[
          this.$store.getters['course/courseCurrentLanguage']
        ];
        this.nextLessonData.courseID = courseID;
        this.nextLessonData.ID = nextID;
      } else {
        const {
          courseid: courseID,
          lessonid: lessonID,
          moduleid: moduleID,
        } = this.$route.params;
        const currentModuleIndex = this.course.modules.findIndex(
          mdl => mdl._id == moduleID
          );

          const nextLessonIndex =
          this.course.modules[currentModuleIndex].lessons.findIndex(
            lesson => lesson._id == lessonID
            ) + 1;

        try {
          this.nextLessonData.courseID = courseID;
          this.nextLessonData.moduleID = moduleID;
          this.nextLessonData.ID = this.course.modules[
            currentModuleIndex
          ].lessons[nextLessonIndex]._id;
          this.nextLessonData.title = this.course.modules[
            currentModuleIndex
          ].lessons[nextLessonIndex].title[
            this.$store.getters['course/courseCurrentLanguage']
          ];
        } catch (e) {
          //
        }
      }
    },
    closeEndDiv() {
      this.endDivShow = false;
    },
    next() {
      if (this.$store.getters['course/isLinear']) {
        this.$router.push({
          name: 'course-linear-lesson-view',
          params: {
            courseid: this.nextLessonData.courseID,
            lessonid: this.nextLessonData.ID,
          },
        });
      } else {
        this.$router.push({
          name: 'course-modular-lesson-view',
          params: {
            courseid: this.nextLessonData.courseID,
            moduleid: this.nextLessonData.moduleID,
            lessonid: this.nextLessonData.ID
          }
        })
      }
    },
  },
};
</script>

<style lang="scss">
.end_div {
  position: absolute;
  background-color: red;
  width: 100%;
  background-color: #02172b;
  min-height: 100%;
  height: 100vh;
  z-index: 2;
  padding-top: 60px;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 10px;
  }

  .end-pointer {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    .icon-border {
      border: 6px solid white;
      border-radius: 85px;
      padding: 30px;
      align-self: center;

      i {
        font-size: 80px;
        color: white;
      }
    }

    .module-title {
      margin-top: 20px;
      align-self: center;
      font-size: 18px;
      color: white;
    }
  }
}
</style>
