import apiUtils from '../helpers/api_utils';
import fileUtils from '../helpers/file_utils';
import store from '../store';

const ROUTE = 'file/';

export const downloadCourseMaterial = async (
  serveFilename,
  userFriendlyFilename
) => {
  const TOKEN = store.getters['auth/userToken'];

  const result = await apiUtils.get(
    `${ROUTE}material/${serveFilename}`,
    {},
    TOKEN
  );

  const extension = serveFilename.split('.').pop();
  const type = fileUtils.getMimeTypeByExtension(extension);
  fileUtils.download({
    name: userFriendlyFilename,
    type,
    data: result.body,
    isBase64: true,
  });
};

export const avatarImageURL = user => {
  if (user.avatar) {
    return apiUtils.buildURL(`${ROUTE}user/${user.avatar}`);
  } else {
    return apiUtils.buildURL(`${ROUTE}image/defaultpic.jpeg`);
  }
};
