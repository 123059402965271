<template>
  <div class="es-info-last-attempt screens-modules">
    {{ maxAttemptsNumber }}
  </div>
</template>

<script>
export default {
  computed: {
    // systemSettings: {
    //   maxAttemptsNumber: 0,
    //   timeBetweenAttempts: 0, // in hours
    // },
    maxAttemptsNumber() {
      let attemptsNumber = this.$store.state.exam.systemSettings.maxAttemptsNumber
      let infoAttempsNumber = this.$i18n.t('labels.maxAttempsNumbers', {
          attemptsNumber
        });
      return infoAttempsNumber;
    },
  },
};
</script>

<style lang="scss">
.es-info-last-attempt {
  padding: 48px !important;
  text-align: center;
}
</style>
