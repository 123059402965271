<template>
  <div class="es-question-cards">
    <!-- {{ questions }} -->
    <v-card
      elevation="0"
      class="question"
      v-for="(item, index) in questions"
      :key="index"
    >
      <v-card-title>
        {{ item.title[language] }}
      </v-card-title>
      <v-card-text>
        <div v-if="hasImage(item)">
          <img
            class="question-image"
            :src="getImage(item)"
            :alt="item.title[language]"
            @click="() => openImage(item)"
          />
        </div>
        <v-radio-group
          v-if="item.type === '0'"
          v-model="userAnswers[item.id]"
          @change="checkValidForm"
        >
          <v-radio
            v-for="(elem, idx) in item.answers"
            :key="'ar_' + idx"
            :label="elem.title[language]"
            :value="elem.id"
          />
          <div v-if="showingAnswers">
            <div v-for="(elem, idx) in item.answers" :key="'ara_' + idx">
              <div v-if="elem.id == item.result" class="item_answer_show">
                {{ elem.title[language] }}
              </div>
            </div>
          </div>
        </v-radio-group>

        <div v-else class="check_responses">
          <v-checkbox
            class="check_responses--item"
            v-for="(elem, idx) in item.answers"
            :key="'ac_' + idx"
            :label="elem.title[language]"
            hide-details
            :name="'check_' + item.id"
            :value="elem.id"
            @change="setChecker(item.id)"
          />
          <div v-if="showingAnswers">
            <div v-for="(elem, idx) in item.answers" :key="'aca_' + idx">
              <div v-if="elem.check" class="item_answer_show">
                {{ elem.title[language] }}
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-btn
      color="primary"
      elevation="0"
      large
      :disabled="!validForm"
      @click="save"
      >{{ $i18n.t('labels.save') }}</v-btn
    >
  </div>
</template>

<script>
import apiUtils from '../../../../../helpers/api_utils';

export default {
  computed: {
    showingAnswers() {
      return this.$store.state.exam.examQuestionsData.showingAnswers;
    },
    questions: {
      get() {
        return this.$store.state.exam.examQuestionsData.questions;
      },
      set(value) {
        this.$store.state.exam.examQuestionsData.questions = value;
      },
    },
    userAnswers: {
      get() {
        return this.$store.state.exam.examQuestionsData.userAnswers;
      },
      set(value) {
        this.$store.state.exam.examQuestionsData.userAnswers = value;
      },
    },
    language() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
    validForm: {
      get() {
        return this.$store.state.questions.view.validForm;
      },
      set(value) {
        this.$store.state.questions.view.validForm = value;
      },
    },
  },
  methods: {
    checkValidForm() {
      const valids = Object.keys(this.userAnswers).every(field => {
        return Array.isArray(this.userAnswers[field])
          ? this.userAnswers[field].length > 0
          : this.userAnswers[field] !== '';
      });
      this.validForm =
        valids && this.questions.length == Object.keys(this.userAnswers).length;
    },
    async setChecker(nameID) {
      this.$nextTick(function() {
        var els = window.document.getElementsByName('check_' + nameID),
          choices = [];
        for (var i = 0; i < els.length; i++) {
          if (els[i].checked) {
            choices.push(els[i].value);
          }
        }
        this.userAnswers[nameID] = choices;
        this.checkValidForm();
      });
    },
    hasImage(item) {
      return item.image && item.image[this.language];
    },
    getImage(item) {
      const apiURL = apiUtils.getURL();
      const url = `${apiURL}file/course/question/${item.image[this.language]}`;
      return url;
    },
    openImage(item) {
      this.$store.dispatch('media/openModal', this.getImage(item));
    },
    async save() {
      this.$gtm.trackEvent({
        category: 'course-internal',
        event: 'click',
        action: 'course-internal-click',
        label: `course-internal-finish-exam`,
      });

      window.scrollTo(0, 0);
      await this.$store.dispatch('exam/saveAttempt');
    },
  },
};
</script>

<style lang="scss">
.v-btn {
  margin-top: 15px;
}
.es-question-cards {
  .item_answer_show {
    background-color: #2a3c4d;
    border-radius: 4px;
    margin-bottom: 3px;
    padding: 3px 8px;
  }
  .check_responses--item {
    margin-bottom: 12px;
  }
  .v-card.question {
    margin-bottom: 20px;
    background-color: #0c2033 !important;
    padding: 8px 30px;

    .theme--light.v-label {
      color: #fff !important;
    }
    .mdi-radiobox-blank::before,
    .mdi-checkbox-blank-outline::before {
      color: #2b4d6c;
    }
  }
  .grade {
    color: rgb(78, 78, 78);
    font-size: 13px;
    position: absolute;
    top: 29px;
    right: 10px;
    text-align: right;
  }
  .grade-color {
    color: rgb(27, 114, 0);

    &.fail {
      color: rgb(175, 0, 0);
    }
  }
  .v-btn__content {
    color: black !important;
  }
  .question-image {
    cursor: pointer;
    width: 30%;
    padding: 14px;
    margin: auto;
  }
}
</style>
